<template>
  <div class="loginScreenWrapper">
    <div class="leftHalf" id="leftHalf">
      <div class="loginForm">
        <img src="../../assets/images/IbiS_logo.png" class="logo"
          v-if="!isDesktop" />

        <span class="headline"> Anmelden </span>
        <input type="text" placeholder="Kundennummer" v-model="customerId"
          id="idInput" @input="validateId()" />
        <input type="password" placeholder="Passwort" v-model="password"
          id="passwordInput" @input="validatePassword()"
          @keydown.enter="loginUser()" />

        <div class="loginButtonWrapper">
          <IbisButton buttonClass="primary" buttonText="Anmelden"
            :isLoading="isLoading" @click="loginUser()"></IbisButton>
        </div>
        <div class="noAccountWrapper">
          <span>Kein Konto? </span>
          <span class="registerLink"
            @click="this.$router.push('/registrierung')">Registrieren</span>
        </div>
        <div class="forgotPasswordWrapper">
          <span class="forgotPasswordLink"
            @click="this.$router.push('/passwort_vergessen')">Passwort
            vergessen?</span>
        </div>
      </div>
    </div>
    <div class="rightHalf" id="rightHalf" v-if="isDesktop">
      <img src="../../assets/images/IbiS_logo.png" class="logo" />
      <span>Fachhändlershop</span>
      <div class="imageCarousel">
        <img v-if="loadedImages[carouselIndex]"
          :src="loadedImages[carouselIndex].src" class="carouselImage"
          :class="{ 'fade-in': fadeIn, 'fade-out': fadeOut }"
          id="carouselImage" />

        <!--         <img src="../../assets/images/frame2.png" class="frame" />
 -->
      </div>
    </div>
  </div>
</template>
<script>
import IbisButton from "../shared/IbisButton.vue";
import AuthService from "@/utility/AuthService";
import ToastTypes from "@/models/Types/ToastTypes";
export default {
  components: {
    IbisButton,
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isTablet() {
      return this.$store.getters.isTablet;
    },
    isDesktop() {
      return this.$store.getters.isDesktop;
    },
  },
  data() {
    return {
      customerId: "",
      password: "",
      showToast: false,
      toastTypes: ToastTypes,
      isLoading: false,
      carouselIndex: 0,
      images: [
        require("../../assets/images/LoginPage/11.avif"),
        require("../../assets/images/LoginPage/12.avif"),
        require("../../assets/images/LoginPage/13.avif"),
        require("../../assets/images/LoginPage/14.avif"),
        require("../../assets/images/LoginPage/15.avif"),
        require("../../assets/images/LoginPage/2.avif"),
        require("../../assets/images/LoginPage/3.avif"),
        require("../../assets/images/LoginPage/4.avif"),
        require("../../assets/images/LoginPage/5.avif"),
        require("../../assets/images/LoginPage/6.avif"),
        require("../../assets/images/LoginPage/7.avif"),
        require("../../assets/images/LoginPage/8.avif"),
        require("../../assets/images/LoginPage/9.avif"),
        require("../../assets/images/LoginPage/10.avif"),
        require("../../assets/images/LoginPage/1.avif"),
      ],
      loadedImages: [],

      fadeIn: false,
      fadeOut: false,
    };
  },
  mounted() {
    document.getElementById("leftHalf").classList.add("fade-in-bottom");
    this.isDesktop
      ? document.getElementById("rightHalf").classList.add("fade-in-bottom")
      : null;

    this.images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        this.loadedImages.push(img);
      };
    });

    this.carouselInterval = setInterval(() => {
      this.fadeOut = true;
      setTimeout(() => {
        const nextIndex = (this.carouselIndex + 1) % this.loadedImages.length;

        if (this.loadedImages[nextIndex].complete) {
          this.carouselIndex = nextIndex;
          this.fadeOut = false;
          this.fadeIn = true;
          setTimeout(() => {
            this.fadeIn = false;
          }, 700);
        }
      }, 700);
    }, 4000);
  },
  beforeUnmount() {
    clearInterval(this.carouselInterval);
  },
  methods: {
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === this.toastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    validateForm() {
      let isValid = true;
      if (!this.customerId || isNaN(this.customerId)) {
        if (!this.showToast) {
          this.showToastMessage(
            "Kundennummer darf nicht leer sein.",
            this.toastTypes.ERROR,
            "idInput"
          );
          isValid = false;
        }
      } else {
        document.getElementById("idInput").classList.remove("invalid");
      }

      if (!this.password) {
        if (!this.showToast) {
          this.showToastMessage(
            "Passwortfeld ist ungültig.",
            this.toastTypes.ERROR,
            "passwordInput"
          );
        }
        isValid = false;
      } else {
        document.getElementById("passwordInput").classList.remove("invalid");
      }

      return isValid;
    },

    validateId() {
      if (!this.customerId || isNaN(this.customerId)) {
        document.getElementById("idInput").classList.add("invalid");
        if (!this.showToast) {
          this.showToastMessage(
            "Kundennummerfeld ist ungültig.",
            this.toastTypes.ERROR,
            "passwordInput"
          );
        }
      } else {
        document.getElementById("idInput").classList.remove("invalid");
      }
    },
    validatePassword() {
      if (!this.password) {
        document.getElementById("passwordInput").classList.add("invalid");
      } else {
        document.getElementById("passwordInput").classList.remove("invalid");
      }
    },
    async loginUser() {
      this.isLoading = true;
      if (this.validateForm()) {
        try {
          await AuthService.login(this.customerId, this.password);
          this.isLoading = false;
          window.location.reload();
          this.$router.push("/");

        } catch (e) {
          this.isLoading = false;
          if (!this.showToast) {
            this.showToastMessage(e.message, this.toastTypes.ERROR);
          }
        }
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.loginScreenWrapper {
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;

  @media (max-width: $tablet) {
    height: 70vh;
  }

  .leftHalf {
    padding-left: 8em;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile) {
      padding-left: 0em;
      display: block;
      width: 100%;
      padding: 2rem;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      display: flex;
      width: 100%;
      padding: 2rem;
    }

    .logo {
      width: 10rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    .loginForm {
      width: 60%;
      max-width: 300px;
      box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
      border-radius: 25px;
      background-color: white;
      border: solid 2px $ibis-grey;
      padding: 3rem 4rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2em;

      @media (max-width: $mobile) {
        padding-left: 0em;
        width: 100%;
        box-shadow: none;
        border: none;
        padding: 0;
        max-width: none;
      }

      @media (min-width: $mobile) and (max-width: $tablet) {
        padding-left: 0em;
        border: none;
        width: 100%;
        max-width: none;
        padding: 0;
        max-width: 50vw;
        box-shadow: none;
      }

      .headline {
        font-size: $font-size-xlarge;
        font-weight: bold;
        margin-bottom: 0.5em;
      }

      input {
        width: 100%;
        text-align: start;
        font-size: $font-size-big;
        padding-left: 1.5em;
        box-sizing: border-box;
      }

      .loginButtonWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;
        margin-bottom: 1em;
      }

      .noAccountWrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5em;

        .registerLink {
          color: $ibis-red;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .forgotPasswordWrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5em;

        .forgotPasswordLink {
          color: $ibis-red;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .rightHalf {
    padding-right: 8em;
    width: 50%;
    height: 80%;
    display: flex;
    gap: 2em;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      max-width: 20rem;
      margin-top: 3rem;
    }

    span {
      font-size: $font-size-large;
      font-weight: bold;
    }

    .imageCarousel {
      width: 30vw;
      max-height: 500px;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      border: 1.5rem solid $ibis-black;
      box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);

      .carouselImage {
        width: 100%;
        margin-top: 0;
        object-fit: fill;
        opacity: 1;
        transition: opacity 1s;
        z-index: 1;
      }

      .frame {
        z-index: 1;
        width: 100%;
        position: absolute;
      }
    }
  }
}

input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  //font-size: $font-size-big;
  text-align: center;
}

.invalid {
  border: 2px solid $ibis-red;
  color: $ibis-red;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
